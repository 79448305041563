// loading.service.ts
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private defaultLoadingText = ""
  private loadingTextSubject = new BehaviorSubject<string>(this.defaultLoadingText);
  public loadingText$ = this.loadingTextSubject.asObservable();

  constructor(private  spinner: NgxSpinnerService){}

  updateText(text:string = this.defaultLoadingText){
    this.loadingTextSubject.next(text);
  }

  show() {
    this.spinner.show();
  }

  hide() {
    this.spinner.hide();
  }
}
