import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FullMetadata } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { Gallery, GalleryImageDef, GalleryItem, ImageItem } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { BehaviorSubject, Observable, from, of, tap } from 'rxjs';
import { catchError, map, mergeMap, take, toArray } from 'rxjs/operators';
import { FirestoreService } from 'src/app/services/firestore.service';
import { StorageService } from 'src/app/services/storage.service';

interface ListItem {
  title: string;
  fileName: string;
  thumbStoragePath:string;
  srcStoragePath:string
}

@Component({
  host: {
    'class': 'page'
  },
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          top: '0',
        })
      ),
      state(
        'out',
        style({
          top: '100%',
        })
      ),
      transition('out => in', animate('350ms ease-in')),
      transition('in => out', animate('350ms ease-out')),
    ]),
  ]
})
export class ImageGalleryComponent implements OnInit, OnDestroy {
  @ViewChild(GalleryImageDef) imageDef?: GalleryImageDef;
  items: ImageItem[] = [];
  itemsSubject = new BehaviorSubject<ImageItem[]>([]);
  items$: Observable<ImageItem[]> = this.itemsSubject.asObservable();
  accessCode: string | null = null;
  hostName: string = '';
  selector: string = "#scrollableDiv";
  public scrollDivState: 'out' | 'in' = 'out';
  public totalCount: number = 0;
  private compressedImagesPath = "gallery/images-compressed";
  private unCompressedImagesPath = "gallery/images";
  private loadingMore = false;
  private nextPageToken: string | null = null;
  private itemsPerPage = 10; 

  constructor(public gallery: Gallery, public lightbox: Lightbox, private storageService: StorageService,
    private activatedRoute: ActivatedRoute,    private fireStoreService: FirestoreService,    private router: Router,) {
      this.hostName = String(
        this.fireStoreService.publicData?.hostName
      ).toUpperCase();
    }

  ngOnInit() {
    this.accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    this.storageService.wrapObservableLoading(this.getFiles(this.nextPageToken, this.itemsPerPage)).pipe(take(1)).subscribe();
  }

  ngAfterViewInit(): void {
    this.items$.pipe(
      tap((items: ImageItem[]) => {
        this.loadItemsIntoGallery(items);
      }),
      catchError((error) => {
        console.error('Error loading files', error);
        return [];
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.gallery.ref('lightbox').destroy();
  }

  onScroll(): void {
    if (!this.loadingMore && this.nextPageToken !== null) {
      this.loadMoreItems(true);
    }
  }
  setUploadUrl() {
    // Handle successful confirmation
    this.router.navigate([this.accessCode, 'drop']);
  }
  public toggleScrollDiv() {
    this.scrollDivState = this.scrollDivState === 'in' ? 'out' : 'in';
  }
  async promptFileShare(item:any): Promise<void> {
    const listItem:ListItem = item.args;
    this.storageService.wrapObservableLoading(this.fetchSrcImageBlob(listItem)).pipe(take(1),
    catchError((err)=>{alert('An error occured. Please try again later'); throw err})).subscribe(
      (blob)=>{
        const file = new File([blob], listItem.fileName, {
          type: blob.type, // optional, depending on whether you want to retain the original MIME type
          lastModified: new Date().getTime() // optional, sets the last modified time to the current time
      });
        if (navigator.share) {
            navigator.share({
              files: [file],
              title: '',
              text: '',
            }).then(()=>console.log('File shared successfully'))
            .catch((error)=>console.error('Error sharing file:', error));          
        } else {
          console.error('Web Share API is not supported in this browser.');
          if(confirm('Web Share not supported in this browser. Do you want to download the photo?'))
            {
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = listItem.fileName;
              link.click();
              URL.revokeObjectURL(link.href);
            }
        }
      }
    )
  }

  private loadMoreItems(showLoading:boolean=false): void {
    this.getFilesAsync(this.nextPageToken, this.itemsPerPage,showLoading).pipe(take(1)).subscribe();
  }

  private getFiles(pageToken: string | null, itemsPerPage: number): Observable<any> {
    return  this.storageService
      .getPaginatedDownloadUrlsAndMetadata(`${this.accessCode}/${this.compressedImagesPath}`, itemsPerPage, pageToken)
      .pipe(
        take(1),
        mergeMap((result) => {
          this.totalCount = result.totalCount;
          this.nextPageToken = result.nextPageToken
          return from(result.files).pipe(
            mergeMap((file) => this.createImageItem(file))
          );
        }),
        toArray(), // ensure all items exist before processing
        mergeMap((items)=>{
          // Get the current list of items
          this.addItemsToGallery(items);
          return items
        }),
        catchError((error) => {
          console.error('Error loading files', error);
          return of<void>(undefined);
        })
      );
  }


  private getFilesAsync(pageToken: string | null, itemsPerPage: number,showLoading:boolean=false): Observable<void|ImageItem[]> {
    const getUrlAndMetadata = showLoading?this.storageService.wrapObservableLoading(this.storageService
      .getPaginatedDownloadUrlsAndMetadata(`${this.accessCode}/${this.compressedImagesPath}`, itemsPerPage, pageToken)):
      this.storageService
      .getPaginatedDownloadUrlsAndMetadata(`${this.accessCode}/${this.compressedImagesPath}`, itemsPerPage, pageToken)
    return getUrlAndMetadata
      .pipe(
        take(1),
        mergeMap((result) => {
          this.totalCount = result.totalCount;
          this.nextPageToken = result.nextPageToken
          return from(result.files).pipe(
            mergeMap((file) => {
              const item = this.createImageItem(file);
              return item
            }),
            tap((imageItem)=> this.addItemsToGallery(imageItem)),
            toArray(),// ensure all items where processed and return
          );
        }),
        catchError((error) => {
          console.error('Error loading files', error);
          return of<void>(undefined);
        })
      );
  }

  private createImageItem(file: { url: string, metadata: FullMetadata }): Observable<ImageItem> {
    const newListItem:ListItem = { 
      title: String(file.metadata.customMetadata?.['guestName']),
      fileName: file.metadata.name,
      thumbStoragePath: `${this.accessCode}/${this.compressedImagesPath}/` + file.metadata.name,
      srcStoragePath: `${this.accessCode}/${this.unCompressedImagesPath}/` + file.metadata.name 
    };
    return this.fetchThumbImageBlob(newListItem).pipe(
      map((blob) => {
        const url = URL.createObjectURL(blob);
        const imageItem = new ImageItem({ src: url, thumb: url, type: 'image', alt: `${newListItem.title}`,args:{...newListItem} });
        return imageItem;
  
      })
    );
  }
  private addItemsToGallery(items: ImageItem[]|ImageItem) {

    if (!Array.isArray(items)) {
      items = [items];
    }
    const currentItems = this.itemsSubject.getValue();

    // Add the new item to the list
    const updatedItems = [...currentItems, ...items];

    // Sort the items by filename in descending order (newest first)
    updatedItems.sort((a, b) => a.data?.args.fileName.localeCompare(b.data.args.fileName));

    // Emit the sorted list
    this.itemsSubject.next(updatedItems);
  }
  
  private fetchThumbImageBlob(item: ListItem): Observable<Blob> {
    return this.storageService.getBlobAsObs(item.thumbStoragePath);
  }
  private fetchSrcImageBlob(item: ListItem): Observable<Blob> {
    return this.storageService.getBlobAsObs(item.srcStoragePath);
  }

  private loadItemsIntoGallery(items: GalleryItem[]): void {
    this.gallery.ref('lightbox', {
      imageTemplate: this.imageDef?.templateRef
    }).load(items);
  }


}
