import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileDropEvent } from '../../directives/file-drop.directive';

export interface IFileInfo {
  file: File;
  blobUrl: string;
}

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
  host: {
    class: ' d-inline-block h-100' 
  },
})
export class FileSelectorComponent {
  @Input() public fileInfoList: IFileInfo[] = [];
  @Input() public acceptedMIMETypes: string[] = [];
  @Input() public selectorType?:'SNAP'|'DROP';
  @Output() public fileChanged: EventEmitter<IFileInfo[]|null> = new EventEmitter<IFileInfo[]|null>();
  @Output() public submitPressed: EventEmitter<IFileInfo[]> = new EventEmitter<IFileInfo[]>();
  public invalidFileType: boolean = false;
  public isFileOver: boolean = false;

  constructor() {}

  public writeValue(obj: any): void {
      this.fileInfoList.push(obj);
  }

  public onFileOver(event: FileDropEvent): void {
    if (event.fileOver !== undefined) {
      this.isFileOver = event.fileOver;
    }
  }

  public onFileDrop(event: FileDropEvent): void {
    const file = event.file;
    if (file) {
      this.invalidFileType = false;  // Reset the indicator for each new file
      if (!this.isValidFileType(file)) {
        this.invalidFileType = true;  // Set to true if file type is invalid
        return;
      }
      this.addFile(file);
    }
  }
  
  public uploadFile(event: Event): void {
    const files = (event?.target as HTMLInputElement)?.files;
    if (files && files.length > 0) {
      this.invalidFileType = false;  // Reset the indicator at the start of the operation
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!this.isValidFileType(file)) {
          this.invalidFileType = true;  // Set to true if any file type is invalid
          continue;  // Skip adding this file if it's not a valid type
        }
        this.addFile(file);
      }
    }
  }
  

  public removeSelectedFile(removeFileInfo: any): void {
    this.fileInfoList = this.fileInfoList.filter(
      fileInfo => fileInfo && fileInfo.file && fileInfo.file.name !== removeFileInfo.file.name
    );
    if(this.selectorType ==='DROP') 
      this.fileChanged.emit(this.fileInfoList);
    else
      this.fileChanged.emit(null);
  }
  
  public openUrl(url: string): void {
    const newWindow = window.open("", "_blank");
    const img = new Image();
    img.src = url;
    newWindow?.document.body.appendChild(img);
  };

  private addFile(file: File) {
    const blob = new Blob([file]);
    const blobUrl = URL.createObjectURL(blob);
    const addFileInfo = { file, blobUrl };
    if(this.selectorType ==='DROP'){
      this.fileInfoList.push(addFileInfo);
      this.fileChanged.emit(this.fileInfoList);
    }
    else
      this.fileChanged.emit([addFileInfo]);
  }

  private isValidFileType(file: File): boolean {
    return this.acceptedMIMETypes.some(type => {
      if (type.endsWith('/*')) {
        return file.type.startsWith(type.slice(0, -1));
      }
      return file.type === type;
    });
  }
  
}