import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { FirestoreService } from 'src/app/services/firestore.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-acknowledgement',
  templateUrl: './acknowledgement.component.html',
  styleUrls: ['./acknowledgement.component.scss']
})
export class AcknowledgementComponent {
  @Input() public shareTitle?:string;
  @Input() public shareText?:string;
  @Input() public backComponent?:"capture"|"record";

  public imageUrl: string ='';
  private accessCode: string|null = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService:StorageService,
  ) {}
  public ngOnInit(): void {
    this.accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    if (this.accessCode) 
    {
        this.storageService.getBlobHostPhoto(this.accessCode).pipe(take(1)).subscribe((blob:Blob|null) => {
          if(blob){
            this.imageUrl = URL.createObjectURL(blob);
          }       
        });
    }
  }
  public navigateBack(){
    this.router.navigate([this.accessCode, this.backComponent]);
  }

  public share() {
    if (navigator.share) {
      navigator.share({
        title: this.shareTitle,  
        text: this.shareText,
        url: window.origin + `/${this.accessCode}/${this.backComponent}`,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      // Fallback for browsers that don't support the Web Share API
      alert('Web Share not supported on this browser');
    }
  }
}
