import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { FirestoreService } from 'src/app/services/firestore.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-image-snap',
  templateUrl: './image-snap.component.html',
  styleUrls: ['./image-snap.component.scss'],
})
export class ImageSnapComponent {
  @ViewChild('modalContainer', { read: ViewContainerRef })
  modalContainerRef?: ViewContainerRef;
  public hostName: string = '';
  public accessCode: string = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fireStoreService: FirestoreService,
    private modalService: ModalService,
  ) {
    this.hostName = String(
      this.fireStoreService.publicData?.hostName
    ).toUpperCase();
  }

  public ngOnInit(): void {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    if (accessCode) {
      this.accessCode = accessCode;
    }
  }

  public uploadFiles(files:any[]){
        // In your component
        this.modalService
        .openSendMediaModal(
          this.modalContainerRef!,
          this.accessCode,
          files
        ).pipe(take(1))
        .subscribe({
          next: () => {
            // Handle successful confirmation
            this.router.navigate([this.accessCode,'snap','acknowledgement']);
          },
          error: (error) => {
            alert('An error occured, please try again')
          },
        });

  }

  public setDropUrl(){
      this.router.navigate([this.accessCode, 'drop']);
  }
}
