import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { FirestoreService } from '../services/firestore.service';
import { Observable, tap } from 'rxjs';


export const accessCodeGuard: CanActivateFn = (route, state) => {
  const accessCode = route.paramMap.get('accessCode') || '';
  const fireStoreService = inject(FirestoreService);
  return isValidAccessCode(accessCode,fireStoreService)
};

function isValidAccessCode(code: string , fireStoreService:FirestoreService): Observable<boolean> | boolean {  
  return fireStoreService.accessGranted? fireStoreService.accessGranted:fireStoreService.incrementAccess(code).pipe(
    tap((accessGranted) => { console.log('App Guard Access Granted: ', accessGranted)})
  ); 
}
