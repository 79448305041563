import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,OnDestroy {
  title = 'social-event-web';
  // Create a Subject that will emit a value when the component is destroyed
  private destroy$ = new Subject<void>();
  constructor(public loadingService:LoadingService){}
  ngOnInit(): void {
    document.body.className = '';
  }

  ngOnDestroy(): void {
    // Emit a value to complete the observable and prevent memory leaks
    this.destroy$.next();
    this.destroy$.complete();
  }
  
}