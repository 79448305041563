import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { accessCodeGuard } from './guards/access-code.guard';
import { ImageSnapComponent } from './components/image-snap/image-snap.component';
import { ImageDropComponent } from './components/image-drop/image-drop.component';
import { SnapAcknowledgementComponent } from './components/acknowledgements/snap-acknowledgement/snap-acknowledgement.component';
import { DropAcknowledgementComponent } from './components/acknowledgements/drop-acknowledgement/drop-acknowledgement.component';
import { ImageGalleryComponent } from './components/gallery/gallery.component';


const routes: Routes = [
  {
    path: ':accessCode/snap',
    component: ImageSnapComponent,
    canActivate:[accessCodeGuard]
  },
  {
    path: ':accessCode/drop',
    component: ImageDropComponent,
    canActivate:[accessCodeGuard]
  },
  {
    path: ':accessCode/gallery',
    component: ImageGalleryComponent,
    canActivate:[accessCodeGuard]
  },
  {
    path: ':accessCode/snap/acknowledgement',
    component: SnapAcknowledgementComponent,
    canActivate:[accessCodeGuard]
  },
  {
    path: ':accessCode/drop/acknowledgement',
    component: DropAcknowledgementComponent,
    canActivate:[accessCodeGuard]
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
