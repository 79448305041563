// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-container {
  height: 185px;
  width: 222px;
  background-color: #F6F3F1;
}

.media-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: thin solid transparent;
  box-shadow: 0px 0px 5px 10px lightgray;
  animation: 3s pulse 2s infinite ease-in-out;
}

.media-button .fas.fa-video {
  font-size: 120px;
  color: black;
}

.media-button .fas.fa-camera {
  font-size: 120px;
  color: black;
}

.media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 250px;
  min-width: 200px;
}

@media (max-width: 430px) {
  .media-button {
    width: 180px;
    height: 180px;
  }
  .media-button .fas.fa-video {
    font-size: 80px;
  }
  .media-button .fas.fa-camera {
    font-size: 80px;
  }
}
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
