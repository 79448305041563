import { Component } from '@angular/core';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-drop-acknowledgement',
  templateUrl: './drop-acknowledgement.component.html',
  styleUrls: ['./drop-acknowledgement.component.scss']
})
export class DropAcknowledgementComponent {
  public backComponent:string=''
  public shareTitle:string = ''
  public shareTextMsg:string =''
  constructor(private fireStoreService: FirestoreService){}

  public ngOnInit(): void {
    const hostName = this.fireStoreService.publicData?.hostName
    this.shareTitle = 'Guest Gallery';
    this.shareTextMsg = `Contribute a moment to the guest gallery` + (hostName?` of ${hostName}`:'');
  }
}
