// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.square-image {
  max-width: 75%; /* Adjust this value based on your preference */
  max-height: 50vh; /* Same as max-width but in viewport height */
  width: auto;
  height: auto;
  object-fit: cover;
  border-radius: 10px; /* Optional: for rounded corners */
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
