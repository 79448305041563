import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements AfterViewInit {
  @ViewChild('modalElement') modalElement!: ElementRef;
  @Input() modalType: string = '';
  @Input() modalTitle: string = '';

  public userName:string='';

  @Output() close = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();

  private modalInstance?:Modal;

  constructor() {
    // Load the username from localStorage if it exists
    const savedUserName = localStorage.getItem('social-event-web-username');
    if (savedUserName) {
      this.userName = savedUserName;
    }
  }

  public ngAfterViewInit() {
    this.modalInstance = new Modal(this.modalElement.nativeElement, {
      keyboard: false,
      backdrop: 'static'
    });
    this.modalInstance.show();
  }

  public onConfirm(): void {
    // Save the username to localStorage
    localStorage.setItem('social-event-web-username', this.userName);

    this.modalInstance?.hide();
    this.confirm.emit();
  }

  public onClose(): void {
    this.modalInstance?.hide();
    this.close.emit();
  }
}
